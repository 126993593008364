import ApolloClient from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from "apollo-boost";
import { toCanonical } from '../resources/utils';

// import queries from './queries';
const queries = {};
const mutations = {};

const client = new ApolloClient({
  uri: window.REACT_APP_GRAPHQL_ADDR,
  // fetchOptions: {
  //   mode: 'no-cors',
  // },
  request: (operation) => {
    const token = window.sessionStorage.getItem('publishEditorK');
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    })
  }
});

mutations.createBehaviors = (data) => {
  return client.mutate({
    variables: data,
    mutation: gql(`
      mutation createBehaviors (
        $id: UUID!,
        $flow: Flow!,
        $media: Media!,
        $onend: Onend!
      ) {
        upsertBehavior(input: {
          behavior: {
            id: $id,
            flow: $flow,
            media: $media,
            onend: $onend
          }
        }) {
          clientMutationId,
          behavior {
            id
          }
        }
      }
    `)
  });
};

mutations.createArticle = (articleData) => {
    return new Promise(async (resolve, reject) => {
      // - upsert behaviors
      // - upsert article
      // - insert tags for article
      let insertedBehavior = await new Promise((resolve, reject) => {
        mutations.createBehaviors(articleData.behaviors)
          .then((result) => {
            resolve(result);
          }).catch((err) => {
            console.error(err);
            reject(err);
          });
      });

      if (insertedBehavior.data) {
        insertedBehavior = insertedBehavior.data.upsertBehavior.behavior.id
      } else {
        reject(insertedBehavior);
      }

      // await mutations.createArticle(articleData);
      await client.mutate({
        variables: {
          id: articleData.id,
          date: articleData.date,
          publisherId: articleData.publisher ? articleData.publisher.id : articleData.publisherId || null,
          behaviorId: articleData.behaviors.id,
          template: articleData.template || 'LIGHT' // TODO: add this to defaults
        },
        mutation: gql(`
          mutation CreateArticle (
            $id: UUID,
            $date: Datetime!,
            $publisherId: UUID!,
            $behaviorId: UUID!,
            $template: ArticleTemplate!,
          ) {
            upsertArticle (input: {
              article: {
                id: $id,
                date: $date,
                template: $template,
                source: "",
                publisherId: $publisherId,
                behaviorId: $behaviorId
              }
            }) {
              clientMutationId,
              article {
                id
              }
            }
          }      
        `)
      })
      resolve();
    });
};

queries.getPublishers = () => {
  return client.query({
    fetchPolicy: 'network-only',
    query: gql`
    query ListPublishers {
      publishers(condition: {active: true}) {
        nodes {
          id,
          companyName,
          companyFullname,
          cnpj,
          url,
          logo,
          active
        }
      }
    }
  `
  });
};

queries.getSocialMedias = _ => {
  return client.query({
    query: gql`
    query socialMediasList {
      socialMediasList {
        id,
        name,
        icon
      }
    }
    `
  });
}

queries.getPublisher = (publisherId) => {
  return client.query({
    // fetchPolicy: 'network-only',
    variables: { publisherId },
    query: gql`
    query getPublisher ($publisherId: UUID!) {
      publisher (id: $publisherId) {
        id,
        companyName,
        companyFullname,
        cnpj,
        url,
        logo,
        active,
        description,
        customizationsList {
          name,
          value
        },
        socialMediaPublishersList {
          id,
          social {
            id,
            name,
            icon
          },
          url
        }
      }
    }
  `
  });
};

queries.getAllTags = (publisherId) => {
  return client.query({
    fetchPolicy: 'network-only',
    variables: {
      publisherId
    },
    query: gql`
      query allTags ($publisherId: UUID!) {
        tags (condition: {
          publisherId: $publisherId
        }) {
          nodes {
            id,
            name,
            kind,
            canonical
          }
        }
      }
    `
  });
};

queries.getAllTagsEditorship = (publisherId) => {
  return client.query({
    fetchPolicy: 'network-only',
    variables: {
      publisherId
    },
    query: gql`
      query allTags ($publisherId: UUID!) {
        tags (
          condition: {publisherId: $publisherId},
          filter: {kind: {equalTo: EDITORSHIP}},
        ) {
          nodes {
            id,
            name,
            kind,
            canonical
          }
        }
      }
    `
  });
};

mutations.addTagEditorship = (tagInput) => {
  return client.mutate({
    variables: {
      tagInput
    },
    mutation: gql`
    mutation addTagEditorship($tagInput: TagInput!) {
      upsertTag(input: {tag: $tagInput}) {
        tag { id, name, kind, canonical}
      }
    }
    `
  })
};
 
mutations.deleteTagEditorship = (tagID) => {
  return client.mutate({
    variables: {
      tagID
    },
    mutation: gql`
    mutation deleteTag ($tagID: UUID!) {
      deleteTag(input: { id: $tagID }) {
        deletedTagNodeId
      }
    }
    `
  });
};

mutations.updateTagEditorship = (tagID, tagName) => {
  const tagCanonical = toCanonical(tagName);
  return client.mutate({
    variables: {
      tagID,
      tagName,
      tagCanonical
    },
    mutation: gql`
    mutation updateTag($tagID: UUID!, $tagName: String!, $tagCanonical: String!) {
      updateTag(
        input: {
          id: $tagID,
          patch: {
            name: $tagName, 
            canonical: $tagCanonical,
          }
        }) {
        tag { id, name, kind, canonical }
      }
    }
    `
  });
};

mutations.moveArticlesFromCurrentToNewTag = (currentTagUUID, newTagUUID) => {
  return client.mutate({
    variables: {
      currentTagUUID,
      newTagUUID
    },
    mutation: gql`
    mutation moveArticle($currentTagUUID: UUID!, $newTagUUID: UUID!) {
      moveArticle(
        input: {
          from: $currentTagUUID, 
          to: $newTagUUID
        }) {
        integer
      }
    }
    `
  });
};

queries.getCurrentTicker = () => {
  return client.query({
    variables: {},
    fetchPolicy: 'network-only',
    query: `
      query CurrentTicker (
        $publisherId: UUID!,
        $lang: Language = BR
      ) {
        articles (
          condition: {
            publisherId: $publisherId,
            type: "TICKER",
            # status: true
          }
          orderBy: DATE_DESC,
          first: 1,
          offset: 0,
        ) {
          nodes {
            id,
            status,
            date,
            contentsList (
              condition: { language: $lang },
            ) {
              id,
              language,
              title,
              image,
              pagesList (
                first: 1,
                orderBy: SORT_ASC
              ) {
                blocksList (
                  first: 1,
                  orderBy: SORT_ASC
                  condition: {
                    kind: PARAGRAPH
                  }
                ){
                  data
                }
              }
            }
          }
        }
      }
    `
  });
};

queries.getArticles = (publisherId, lang, pageSize = -1, offset = 0, filter = '') => {
  filter = "%" + filter.replace(' ', '%') + "%";

  return client.query({
    variables: {
      publisherId,
      lang,
      pageSize,
      offset,
      filter
    },
    fetchPolicy: 'network-only',
    query: gql`
    query SelectArticlesForPublisher (
      $publisherId: UUID!,
      $lang: Language = BR,
      $filter: String = "%",
      $pageSize: Int,
      $offset: Int
    ) {
      articles(
        orderBy: DATE_DESC,
        first: $pageSize,
        offset: $offset,
        filter: {
          contents: {
            some: {
              canonical: {
                isNull: false,
                likeInsensitive: $filter
            	}
            }
          }
        }
        condition: {
          publisherId: $publisherId
        }) {
        totalCount,
        nodes {
          id,
          date,
          status,
          tags:articleTagsList {
            tag {
              id,
              name
            }
          },
          # author {
          #   firstName,
          #   lastName
          # },
          contents (condition: { language: $lang }) {
            nodes {
              id,
              title,
              language,
              image,
              tophat
            }
          }
        }
      }
    }
    `
  });
};

mutations.removeArticle = (articleId) => {
  return client.mutate({
    variables: {
      articleId
    },
    mutation: gql`
    mutation MyMutation ($articleId: UUID!) {
      deleteArticle(input: { id: $articleId }) {
        deletedArticleNodeId
      }
    }
    `
  });
};

mutations.upsertAuthor = (authorData) => {
  return client.mutate({
    variables: authorData,
    mutation: gql`
      mutation author (
        $id: UUID,
        $firstName: String!,
        $lastName: String
      ) {
        upsertAuthor (input: {
          author: {
            id: $id,
            firstName: $firstName,
            lastName: $lastName
          }
        }) {
          author {
            id,
            firstName,
            lastName
          }
        }
      }
    `
  });
};

function clearTypeName (obj, alsoRemove = []) {
  if (Array.isArray(obj)) {
    return obj.map(item => clearTypeName(item, alsoRemove));
  }

  if (typeof obj === 'string') {
    return obj;
  }

  const { __typename, ...others } = obj; // eslint-disable-line
  alsoRemove.forEach(item => {
    delete others[item];
  })
  return others;
}

mutations.createTag = async (tagInput) => {
  return client.mutate({
    variables: {
      tagInput
    },
    mutation: gql`
    mutation createTagEDITORSHIP($tagInput: TagInput!) {
      upsertTag(input: {tag: $tagInput}) {
        tag { id, name, kind, canonical }
      }
    }
    `
  })
}

mutations.upsertDeepArticleData = async (articleData) => {
  const behavior = clearTypeName(articleData.behaviors);
  const mutVariables = {
    articleId: articleData.id,
    date: articleData.date,
    publisherId: articleData.publisher ? articleData.publisher.id : articleData.publisherId || null,
    behaviorId: behavior.id,
    behavior,
    authorId: articleData.author ? articleData.author.id : null,
    tags: clearTypeName(articleData.tags || []).map(tag => {
      tag.canonical = toCanonical(tag.name);
      return tag;
    }),
    source: articleData.source || '',
    status: articleData.status || null,
    type: articleData.type || 'ARTICLE',
    contents: articleData.contents.map(content => {
      content.image = content.image || (content.images ? content.images[0] : null);
      if (Array.isArray(content.image)) {
        content.image = content.image[0]; // retro-compatibility with previous versions
      }

      content.canonical = toCanonical(content.title);

      delete content.images;
      return clearTypeName({
        ...content,
        articleId: articleData.id
      }, ['pages']);
    }),
    template: articleData.template || 'LIGHT'
  };
// debugger;
  const mutQr = `
  mutation upsertDeepArticle (
    $articleId: UUID!,
    $authorId: UUID,
    $date: Datetime!,
    $template: ArticleTemplate!,
    $source: String!,
    $status: Boolean,
    $type: String = "ARTICLE",
    $publisherId: UUID!,
    $behavior: BehaviorInput,
    $behaviorId: UUID!,
    $tags: [TagInput],
    $contents: [ContentInput]
  ) {
      upsertDeepArticle(input: {
        article: {
          id: $articleId,
          authorId: $authorId,
          date: $date,
          template: $template,
          source: $source,
          type: $type,
          status: $status,
          publisherId: $publisherId,
          behaviorId: $behaviorId
        },
        content: $contents,
        behavior: $behavior,
        tag: $tags
      }) {
        clientMutationId
      }
    }
`;

  const mut = {
    variables: mutVariables,
    mutation: gql(mutQr)
  };

  return client.mutate(mut);
};

// remove all customizations for a given publisher
mutations.removePublisherCustomizations = async (publisherId, customName = '*') => {
  return client.mutate({
    variables: { publisherId, customName },
    mutation: gql(`
      mutation removePublisherCustomizations ($publisherId: UUID!, $customName: String = "*") {
        deleteCustomizationByNameAndPublisherId (input: {publisherId: $publisherId, name: $customName}) {
          deletedCustomizationNodeId
        }
      }
    `)
  });
};

// add a customization for publisher
mutations.addPublisherCustomizations = async (publisherId, customName = "*", customValue = "{}") => {
  return client.mutate({
    variables: {
      publisherId,
      customName,
      customValue
    },
    mutation: gql(`
      mutation addPublisherCustomizations ($publisherId: UUID!, $customName: String = "*", $customValue: JSON = "{}") {
        createCustomization (input: {
          customization: {
            name: $customName,
            value: $customValue,
            publisherId: $publisherId
          }
        }) {
          customization {
            name,
            value
          }
        }
      }
    `)
  });
}

mutations.updatePublisher = async (publisherData) => {
  publisherData.companyFullname = publisherData.companyFullname || publisherData.companyName;
  return client.mutate({
    variables: publisherData,
    mutation: gql(`
      mutation upsertPublisher (
        $id: UUID!,
        $companyName: String!,
        $companyFullname: String!,
        $logo: String!,
        $url: String!,
        $active: Boolean,
  			$cnpj: String!,
  			$description: String
      ) {
        upsertPublisher (input: {
          publisher: {
            id: $id,
            companyName: $companyName,
            companyFullname: $companyFullname,
            cnpj: $cnpj,
            active: $active,
            logo: $logo,
            url: $url,
            description: $description
          }
        }) {
          publisher {
            id,
            companyName,
            cnpj,
            active,
            logo,
            url,
            description
          }
        }
      }
    `)
  });
};

mutations.upsertDeepArticleContent = async (articleData) => {
  // const behavior = clearTypeName(articleData.behaviors);
  const pagesList = [];
  const blocksList = [];
  const contentIds = new Set();

  articleData.contents.forEach(content => {
    contentIds.add(content.id);
    content.pages.forEach((page, i) => {
      pagesList.push({
        id: page.id,
        sort: i,
        contentId: content.id
      });

      page.blocks.forEach((block, i) => {
        blocksList.push({
          id: block.id,
          kind: block.kind,
          sort: block.sort || i,
          data: JSON.stringify(block.data),
          pageId: page.id
        });
      });
    });
  });

  // debugger;

  return client.mutate({
    variables: {
      pages: pagesList,
      blocks: blocksList,
      contentIds: Array.from(contentIds)
    },
    mutation: gql(`
    mutation upsertDeepArticleContent (
        $pages: [PageInput],
        $blocks: [BlockInput],
        $contentIds: [UUID]
      ) {
          upsertDeepContent (input: {
            page: $pages,
            block: $blocks,
            contentids: $contentIds
          }) {
            clientMutationId,
            integer
          }
        }
    `)
  });
};

queries.getArticleContent = (publisherId, articleId) => {
  return client.query({
    variables: {
      publisherId,
      articleId
    },
    fetchPolicy: 'network-only',
    query: gql`
      query ArticleContent (
        $publisherId: UUID!,
        $articleId: UUID!
      ) {
        articles(condition: {
          publisherId: $publisherId,
          id: $articleId
        }){
          article: nodes {
            id,
            date,
            publisherId,
            type,
            sponsor {
              id,
              name,
              url,
              logo
            },
            author {
              id,
              firstName,
              lastName
            },
            tags:articleTagsList {
              tag {
                id,
                name,
                kind,
                publisherId
              }
            }
            behaviors:behavior {
              id,
              onend,
              flow,
              media
            }
            contents {
              nodes {
                id
                language
                title
                image,
                tophat,
                pages {
                  totalCount
                  nodes {
                    id,
                    sort,
                    blocks {
                      nodes {
                        id,
                        kind,
                        data,
                        sort
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  });
};

export {
  client,
  useQuery,
  useMutation,
  gql,
  queries,
  mutations,
  clearTypeName
};

export default client;
